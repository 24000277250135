
import StringUtils from "@/utils/StringUtils.js";

export default class ContentUtils {

  static set(kvps, kvp, logger) {
    if (ContentUtils.isOk(kvp.key, kvp.value)) {
      var valueStr = kvp.value;
      if (typeof kvp.value === 'string' || kvp.value instanceof String) {
        valueStr = StringUtils.replace(valueStr, "\t", " ");
        valueStr = StringUtils.replace(valueStr, "\n", " ");
        valueStr = StringUtils.replace(valueStr, "\"", "");
      }
      kvps[kvp.key] = valueStr;
    } else {
      kvps[kvp.key] = "";
    }
    if (logger) {
      var out = JSON.stringify(kvps, null, 2)
      logger.info(out);
    }
  }

  static hasValues(kvps, keys) {
    for (var i = 0; i < keys.length; i++) {
      if (!ContentUtils.hasValue(kvps, keys[i])) {
        return false;
      }
    }
    return true;
  }

  static hasValue(kvps, key) {
    var value = kvps[key];
    if (value) {
      value = value.toString();
    }
    return StringUtils.isOk(value);
  }

  static getParam(paramValue, defaultValue) {
    if (paramValue) {
      return paramValue;
    }
    return defaultValue;
  }
  
  static getParamInt(paramValue, defaultValue) {
    if (paramValue) {
      var value = parseInt(paramValue);
      if (!isNaN(value)) {
        return value;
      }
    }
    return defaultValue;
  }
  
  static getValue(kvps, key) {
    var value = kvps[key];
    if (value) {
      value = value.toString();
    }
    return value;
  }

  static get(rcObject, key) {
    if (rcObject) {
      return rcObject.getString(key);
    }
    return "";
  }

  static has(array) {
    var keys = null;
    if (Array.isArray(array)) {
      for (var i = 0; i < array.length; i++) {
        var element = array[i];
        keys = Object.keys(element);
        if (keys.length == 0) {
          return false;
        }
      }
    } else {
      keys = Object.keys(array);
      if (keys.length == 0) {
        return false;
      }
    }
    return true;
  }

  static isOk(key, value) {
    if (key) {
      //
    }
    if (typeof value === "boolean") {
      return true;
    }
    if (Array.isArray(value)) {
      return true;
    }
    if (StringUtils.isNull(value)) {
      return false;
    }
    if (StringUtils.isEmpty(value)) {
      return true;
    }
    if (StringUtils.isOk(value)) {
      return true;
    }
    return false;
  }

  static escape(text) {
    var encoded = encodeURI(text);
    while (encoded.indexOf("%20") != -1) {
      encoded = encoded.replace("%20", " ");
    }
    //Don't do this, we don't want to encode the @rid values.
    //encoded = encoded.replace("#", "%23");
    return encoded;
  }

  static encode(text) {
    return ContentUtils.escape(text);
  }

  static decode(text) {
    return ContentUtils.unescape(text);
  }

  static decodeText(text) {
    var value = "";
    if (text) {
      value = decodeURI(text);
      value = value.replace("%23", "#");
    }
    return value;
  }

  static unescape(text) {
    var value = "";
    if (text) {
      value = decodeURI(text);
      if (value.indexOf("@") == -1) {
        value = value.replace(/\+/g, " ");
      }
      value = value.replace("%23", "#");
    }
    return value;
  }

  static unescapeEmail(text) {
    if (text) {
      var value = decodeURI(text);
      return value;
    }
    return "";
  }

  static encodeDate(date) {
    if (!date || date.trim().length == 0) {
      return null;
    }
    var items = date.split("-"); //Split the string
    var time = new Date(items[0], items[1] - 1, items[2]);

    var decoded = ContentUtils.decodeDate(time.getTime());
    if (decoded) {
      //
    }
    return time.getTime() + "";
  }

  static decodeDate(value) {
    if (!value)
      return "";

    var longStr = value + ""
    if (!longStr || longStr.trim().length == 0) {
      return "";
    }
    var time = parseInt(longStr, 10);
    var date = new Date(time);
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }

  static decodeDateTime(value) {
    if (!value) {
      return "";
    }
    var longStr = value + ""
    if (!longStr || longStr.trim().length == 0) {
      return null;
    }
    var date = new Date(parseInt(longStr));
    var month = date.getMonth() + 1;
    if (month + 1 < 10) {
      month = "0" + month;
    }
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    //    var offset = date.getTimezoneOffset();
    //    var stdOffset = ContentUtils.stdTimezoneOffset();

    var hour = date.getHours();
    var ampm = "a.m.";
    if (hour > 12) {
      hour = hour - 12;
      ampm = "p.m.";
    }

    var minute = date.getMinutes();
    if (minute < 10) {
      minute = "0" + minute;
    }

    var seconds = date.getSeconds();
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return date.getFullYear() + '-' + month + '-' + day + " " + hour + ":" + minute + ":" + seconds + " " + ampm;
  }

  static stdTimezoneOffset() {
    var date = new Date();

    var jan = new Date(date.getFullYear(), 0, 1);

    var janOffset = jan.getTimezoneOffset();

    return janOffset;
  }

  static sumDisplay(sum) {
    var value = "0";
    if (sum) {
      value = sum.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
  }
  
  static ROLES() {
    var roles = [
      { '@rid': '#31:0', 'name': 'Commercial Driver',     'summary': '', 'details': 'When hours of service are activated for a company, this driver will be required to start the days HOS record before performing a daily inspection.' },
      { '@rid': '#31:7', 'name': 'Non Commercial Driver', 'summary': '', 'details': 'When hours of service are activated for a company, this driver has the option to use it, but is not required.' },
      { '@rid': '#31:3', 'name': 'Manager',               'summary': '', 'details': 'An employee who can use the mobile app with expanded responsibilities and use this Operator Portal. When hours of service are activated for a company, this manager/driver has the option to use it, but is not required.' },
      { '@rid': '#31:4', 'name': 'Owner',                 'summary': '', 'details': 'An owner of this business who  can use the mobile app with expanded responsibilities and use this Operator Portal.   When hours of service are activated for a company, this operator/driver has the option to use it, but is not required.' },
    ];
    return roles;
  }

  static ROLES2() {
    var roles = {
      'driver': 'Commercial Driver',
      'nc_driver': 'Non Commercial Driver',
      'manager': 'Manager',
      'owner': 'Owner'
    };
    return roles;
  }

  static ROLE_DRIVER() {
    return { '@rid': '#31:0', 'name': 'Commercial Driver' };
  }

  static BRAKE_TYPES() {
    return [
             { '@rid': 'abs', name: 'Air' },
             { '@rid': 'ebs', name: 'Electric' },
             { '@rid': 'hbs', name: 'Hydraulic' },
             { '@rid': 'nbs', name: 'None' },
           ];
  }
  
  static UNITS() {
     return [
              { '@rid': 'KM', name: 'Kilometers' },
              { '@rid': 'MI', name: 'Miles' },
            ];
  }
  
  static CATEGORIES() {
    return [
            { '@rid': '#23:1', name: 'Truck'},
            { '@rid': '#23:5', name: 'Trailer'},
            { '@rid': '#23:6', name: 'Bus'},

            { '@rid': '#23:7', name: 'Bucket Truck'},
            { '@rid': '#23:0', name: 'Car'},
            { '@rid': '#23:12', name: 'Cube Van'},
            { '@rid': '#23:8', name: 'Drill Truck'},
            { '@rid': '#23:3', name: 'Dump Truck'},
            { '@rid': '#23:13', name: 'Highway Tractor'},
            { '@rid': '#23:15', name: 'Limo'},
            { '@rid': '#23:11', name: 'Straight Truck'},
            { '@rid': '#23:2', name: 'SUV'},
            { '@rid': '#23:4', name: 'Tow Truck'},
            { '@rid': '#23:14', name: 'Unknown'},
            { '@rid': '#23:9', name: 'Vacuum Truck'},
            { '@rid': '#23:10', name: 'Van'},
   ];
  }
  
  static SOCIALS() {
    return [
      { '@rid': 'AppleStore', 'name': 'Apple App Store' },
      { '@rid': 'GoogleStore', 'name': 'Google Play Store' },
      { '@rid': 'Separator', 'name': '' },
      { '@rid': 'TriShield', 'name': 'Tri-Shield Consulting' },
      { '@rid': 'SafetyDawg', 'name': 'Safety Dawg/CVOR.ca' },
      { '@rid': 'DriverVerified', 'name': 'DriverVerified' },
      { '@rid': 'Summit', 'name': 'Summit Risk Solutions' },
      { '@rid': 'LandscapeOntario', 'name': 'Landscape Ontario Event' },
      { '@rid': 'Separator2', 'name': '' },
      { '@rid': 'LinkedIn', 'name': 'LinkedIn' },
      { '@rid': 'Facebook', 'name': 'Facebook' },
      { '@rid': 'Instagram', 'name': 'Instagram' },
      { '@rid': 'Twitter', 'name': 'Twitter' },
      { '@rid': 'WebSearch', 'name': 'Web Search' },
      { '@rid': 'WebAd', 'name': 'Web Advertisement' },
      { '@rid': 'Separator3', 'name': '' },
      { '@rid': 'Friend', 'name': 'A friend' },
      { '@rid': 'Other', 'name': 'Other' },
    ];
  }

  static REFERRALS() {
    return [
      { '@rid': 'TriShield', 'name': 'Tri-Shield Consulting' },
      { '@rid': 'SafetyDawg', 'name': 'Safety Dawg/CVOR.ca' },
      { '@rid': 'DriverVerified', 'name': 'DriverVerified' },
      { '@rid': 'Summit', 'name': 'Summit Risk Solutions' },
      { '@rid': 'LandscapeOntario', 'name': 'Landscape Ontario Event' },
    ];
  }
  
  static ADS() {
    return [
      { '@rid': 'AppleStore', 'name': 'Apple App Store' },
      { '@rid': 'GoogleStore', 'name': 'Google Play Store' },
      { '@rid': 'LinkedIn', 'name': 'LinkedIn' },
      { '@rid': 'Facebook', 'name': 'Facebook' },
      { '@rid': 'Instagram', 'name': 'Instagram' },
      { '@rid': 'Twitter', 'name': 'Twitter' },
      { '@rid': 'WebSearch', 'name': 'Web Search' },
      { '@rid': 'WebAd', 'name': 'Web Advertisement' },
      { '@rid': 'Other', 'name': 'Other' },
    ];
  }

  static COUNTRYS() {
    return [
      { '@rid': 'Canada', 'name': 'Canada' },
      { '@rid': 'United States', 'name': 'United States' },
    ];
  }

  static FLEET_SIZE() {
    return [
      { '@rid': '1_to_5', 'name': '  1 to 5' },
      { '@rid': '6_to_10', 'name': '  6 to 10' },
      { '@rid': '11_to_20', 'name': ' 11 to 20' },
      { '@rid': '21_to_30', 'name': ' 21 to 30' },
      { '@rid': '31_to_50', 'name': ' 31 to 50' },
      { '@rid': '51_to_75', 'name': ' 51 to 75' },
      { '@rid': '75_to_100', 'name': ' 76 to 100' },
      { '@rid': '101_to_150', 'name': '101 to 150' },
      { '@rid': '151_to_200', 'name': '151 to 200' },
      { '@rid': '201_to_500', 'name': '201 to 500' },
      { '@rid': '501_to_1000', 'name': '501 to 1000' },
    ];
  }

  static PAYMENT_FREQUENCYS() {
    return [
      { '@rid': 'monthly', 'name': 'Monthly' },
      { '@rid': 'quarterly', 'name': 'Quarterly' },
      { '@rid': 'semi-annually', 'name': 'Semi-Annually' },
      { '@rid': 'annually', 'name': 'Annually' },
    ];
  }

  static PAYMENT_METHODS() {
    return [
      { '@rid': 'etransfer', 'name': 'E-Transfer' },
      { '@rid': 'creditcard', 'name': 'Credit Card' },
      { '@rid': 'cheque', 'name': 'Cheque' },
      { '@rid': 'bankwire', 'name': 'Bank Wire' },
    ];
  }

  static STATES() {
    return [
      { '@rid': 'locked', 'name': 'Locked' },
      { '@rid': 'removed', 'name': 'Deleted' },
      { '@rid': 'inactive', 'name': 'Inactive' },
      { '@rid': 'active', 'name': 'Active' },
    ];
  }

  static MONTHS() {
    return [
      { '@rid': '01', 'name': '01' },
      { '@rid': '02', 'name': '02' },
      { '@rid': '03', 'name': '03' },
      { '@rid': '04', 'name': '04' },
      { '@rid': '05', 'name': '05' },
      { '@rid': '06', 'name': '06' },
      { '@rid': '07', 'name': '07' },
      { '@rid': '08', 'name': '08' },
      { '@rid': '09', 'name': '09' },
      { '@rid': '10', 'name': '10' },
      { '@rid': '11', 'name': '11' },
      { '@rid': '12', 'name': '12' },
    ];
  }
  
  static YEARS() {
    return [
      { '@rid': '2022', 'name': '2022' },
      { '@rid': '2023', 'name': '2023' },
      { '@rid': '2024', 'name': '2024' },
      { '@rid': '2025', 'name': '2025' },
      { '@rid': '2026', 'name': '2026' },
      { '@rid': '2027', 'name': '2027' },
      { '@rid': '2028', 'name': '2028' },
      { '@rid': '2029', 'name': '2029' },
      { '@rid': '2030', 'name': '2030' },
    ];
  }
  
  static LICENSES() {
    return [
      { '@rid': 'G', 'name': 'G', 'summary': 'Allowed to drive any car, van or small truck or combination of vehicle and towed vehicle up to 11,000 kilograms.', 'details': 'Provided the towed vehicle is not over 4,600 kilograms. A pickup truck towing a house trailer exceeds 4,600 kilograms but the total combined weight of the truck and trailer does not exceed 11,000 kilograms is deemed a Class G.' },
      { '@rid': 'A', 'name': 'A', 'summary': 'Allowed to drive any tractor-trailer combination. May also drive vehicle in class D and G.', 'details': '' },
      { '@rid': 'AC', 'name': 'AC', 'summary': 'Allowed to drive A trucks and C buses' },
      { '@rid': 'ACZ', 'name': 'ACZ', 'summary': 'Allowed to drive A trucks C buses equiped with Air Brakes' },
      { '@rid': 'AR', 'name': 'AR', 'summary': 'Allowed to drive A trucks with restrictions', 'details': 'With a restricted Class A licence, you cannot pull a trailer equipped with air brakes drive a full-size tractor-trailer drive a vehicle pulling a double trailer train another driver on a vehicle that requires full Class A privileges to drive' },
      { '@rid': 'AZ', 'name': 'AZ', 'summary': 'Allowed to drive A trucks with Air Brakes' },
      { '@rid': 'AZ-GR', 'name': 'AZ-GR', 'summary': 'Allowed to drive A trucks without manual transmission' },
      { '@rid': 'B', 'name': 'B', 'summary': 'Allowed to drive any school purposes bus. May also drive vehicle in class C, D, E, F and G.', 'details': '' },
      { '@rid': 'C', 'name': 'C', 'summary': 'Allowed to drive any regular bus. May also drive vehicles in class D, F and G.', 'details': '' },
      { '@rid': 'D', 'name': 'D', 'summary': 'Allowed to drive a motor vehicle exceeding 11,000 kilograms gross weight or registered gross weight.', 'details': 'Or any truck or combination provided the towed vehicle is not over 4,600 kilograms. May also drive vehicle in class G.' },
      { '@rid': 'DZ', 'name': 'DZ', 'summary': 'Allowed to drive D trucks with equiped with Air Brakes' },
      { '@rid': 'E', 'name': 'E', 'summary': 'Allowed to drive any school purposes bus - maximum of 24-passenger capacity.', 'details': 'May also drive vehicle in class F and G' },
      { '@rid': 'F', 'name': 'F', 'summary': 'Allowed to drive any regular bus, maximum of 24-passenger capacity including ambulances.', 'details': 'May also drive vehicle in class G' },
    ];
  }

  static PROVINCES() {
    return [
      { '@rid': 'Ontario', 'name': 'Ontario', 'country': 'Canada' },
      { '@rid': 'Alberta', 'name': 'Alberta', 'country': 'Canada' },
      { '@rid': 'British Columbia', 'name': 'British Columbia', 'country': 'Canada' },
      { '@rid': 'Manitoba', 'name': 'Manitoba', 'country': 'Canada' },
      { '@rid': 'Quebec', 'name': 'Quebec', 'country': 'Canada' },
      { '@rid': 'Saskatchewan', 'name': 'Saskatchewan', 'country': 'Canada' },
      { '@rid': 'New Brunswick', 'name': 'New Brunswick', 'country': 'Canada' },
      { '@rid': 'Newfoundland and Labrador', 'name': 'Newfoundland and Labrador', 'country': 'Canada' },
      { '@rid': 'Nova Scotia', 'name': 'Nova Scotia', 'country': 'Canada' },
      { '@rid': 'Nunavit', 'name': 'Nunavit', 'country': 'Canada' },
      { '@rid': 'NWT', 'name': 'NWT', 'country': 'Canada' },
      { '@rid': 'PEI', 'name': 'PEI', 'country': 'Canada' },
      { '@rid': 'Yukon', 'name': 'Yukon', 'country': 'Canada' },
      { '@rid': 'Separator', 'name': '' },
      { '@rid': 'Alabama', 'name': 'Alabama' },
      { '@rid': 'Alaska', 'name': 'Alaska' },
      { '@rid': 'Arizona', 'name': 'Arizona' },
      { '@rid': 'Arkansas', 'name': 'Arkansas' },
      { '@rid': 'California', 'name': 'California' },
      { '@rid': 'Colorado', 'name': 'Colorado' },
      { '@rid': 'Connecticut', 'name': 'Connecticut' },
      { '@rid': 'Delaware', 'name': 'Delaware' },
      { '@rid': 'Florida', 'name': 'Florida' },
      { '@rid': 'Georgia', 'name': 'Georgia' },
      { '@rid': 'Hawaii', 'name': 'Hawaii' },
      { '@rid': 'Idaho', 'name': 'Idaho' },
      { '@rid': 'Illinois', 'name': 'Illinois' },
      { '@rid': 'Indiana', 'name': 'Indiana' },
      { '@rid': 'Iowa', 'name': 'Iowa' },
      { '@rid': 'Kansas', 'name': 'Kansas' },
      { '@rid': 'Kentucky', 'name': 'Kentucky' },
      { '@rid': 'Louisiana', 'name': 'Louisiana' },
      { '@rid': 'Maine', 'name': 'Maine' },
      { '@rid': 'Maryland', 'name': 'Maryland' },
      { '@rid': 'Massachusetts', 'name': 'Massachusetts' },
      { '@rid': 'Michigan', 'name': 'Michigan' },
      { '@rid': 'Minnesota', 'name': 'Minnesota' },
      { '@rid': 'Mississippi', 'name': 'Mississippi' },
      { '@rid': 'Missouri', 'name': 'Missouri' },
      { '@rid': 'Montana', 'name': 'Montana' },
      { '@rid': 'Nebraska', 'name': 'Nebraska' },
      { '@rid': 'Nevada', 'name': 'Nevada' },
      { '@rid': 'New Hampshire', 'name': 'New Hampshire ' },
      { '@rid': 'New Jersey', 'name': 'New Jersey' },
      { '@rid': 'New Mexico', 'name': 'New Mexico' },
      { '@rid': 'New York', 'name': 'New York' },
      { '@rid': 'North Carolina', 'name': 'North Carolina' },
      { '@rid': 'North Dakota', 'name': 'North Dakota' },
      { '@rid': 'Ohio', 'name': 'Ohio' },
      { '@rid': 'Oklahoma', 'name': 'Oklahoma' },
      { '@rid': 'Oregon', 'name': 'Oregon' },
      { '@rid': 'Pennsylvania', 'name': 'Pennsylvania' },
      { '@rid': 'Rhode Island', 'name': 'Rhode Island' },
      { '@rid': 'South Carolina', 'name': 'South Carolina' },
      { '@rid': 'South Dakota', 'name': 'South Dakota' },
      { '@rid': 'Tennessee', 'name': 'Tennessee' },
      { '@rid': 'Texas', 'name': 'Texas' },
      { '@rid': 'Utah', 'name': 'Utah' },
      { '@rid': 'Vermont', 'name': 'Vermont' },
      { '@rid': 'Virginia', 'name': 'Virginia' },
      { '@rid': 'Washington', 'name': 'Washington' },
      { '@rid': 'West Virginia', 'name': 'West Virginia' },
      { '@rid': 'Wisconsin', 'name': 'Wisconsin' },
      { '@rid': 'Wyoming', 'name': 'Wyoming' },
    ];
  }
  
  static ChecklistItemTypes() {
    return [
      { '@rid': 'text', 'name': 'Text', 'summary': '', 'details': 'Allows the driver to enter in text' },
      { '@rid': 'number', 'name': 'Number', 'summary': '', 'details': 'Allows the driver to enter in a number' },
      { '@rid': 'list', 'name': 'List', 'summary': '', 'details': 'Allows the driver to pick from a list of possible answers' },
      { '@rid': 'bool', 'name': 'Yes/No', 'summary': '', 'details': 'Allows the driver to pick from one of two options' },
    ];
  }
  
  static ChecklistItemOptionCategories() {
    return [
      { '@rid': 'none', 'name': 'Good',   'summary': '', 'details': 'This is not a problem' },
      { '@rid': 'minor', 'name': 'Minor', 'summary': '', 'details': 'This problem is minor, email sent to safety manager' },
      { '@rid': 'major', 'name': 'Major', 'summary': '', 'details': 'This problem is major, email sent to safety manager' },
    ];
  }
}