<template>

  <div class="rc-row" :class="additional()" :cmpt="cmpt">
    <slot></slot>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'

import Strings from "@/utils/StringUtils.js";

export default {
  name: 'portals-shared-library-box-row',
  props: {
    cmpt: { type: String, default: "row" },
    showBorder: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ]),
  },
  created() {
    const slots = this.$slots.default;
    if (!slots) {
      return;
    }
    const columns = slots.filter(node => Strings.contains(node.tag, 'box-column'));
    if (columns) {
      var number = columns.length;
      if (number) {
        //
      }
    }
  },
  mounted() {
  },
  methods: {
    additional: function () {
      var value = "";
      if (this.showBorder) {
        value += "rc-border-thin ";
      }
      return value;
    },
  },
}
</script>