<template>
  <div>
    <button 
      v-on:click="pressed"
      :class="classes"
      :style="style"
      type=button
      :disabled="disabled"> 
        <slot> </slot> 
    </button>
  </div>
</template>

<script>
export default {
  name: 'portal-shared-library-button-button',
  components: {
  },
  props: {
    disabled: { type: Boolean, default: false },
    go: { type: Boolean, default: false },
    type: { type: String, default: "normal" },
    padding: { type: String, default: "2px" },
  },
  data() {
    return {
    }; 
  },
  computed: {
    classes() {
      if (this.go) {
        return "rc-input-button-normal-go-v2 rc-input-width rc-font-medium";
      }
      if (this.type === "largeAction") {
        return "rc-input-button-normal-go-v2 rc-input-width rc-font-large";
      }
      if (this.type === "mediumAction") {
        return "rc-input-button-normal-go-v2 rc-input-width rc-font-medium";
      }
      if (this.type === "smallAction") {
        return "rc-input-button-normal-go-v2 rc-input-width rc-font-small";
      }
      return "rc-input-button-normalv2 rc-input-width rc-font-small";
    },
    style() {
      return "height: 30px; padding: " + this.padding;
    }
  },
  methods: {
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>
