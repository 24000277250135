
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const BASE_URL = process.env.BASE_URL;

export default new Router({
  mode: 'history',
  base: BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./portals/customer/shared/signin/SignInView.vue')
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import('./portals/customer/shared/signin/SignInView.vue')
//      component: () => import('./views/sign/in/SignIn.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/privacy/Privacy.vue')
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: () => import('./portals/customer/shared/recovery/SignInRecoveryView.vue')
//      component: () => import('./views/password/Lost.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('./portals/customer/shared/register/RegisterView.vue')
//      component: () => import('./views/sign/up/SignUp.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/about/About.vue')
    },
    {
      path: '/signinv0',
      name: 'signinv0',
      component: () => import('./views/sign/in/SignIn.vue')
    },
    {
      path: '/signout',
      name: 'signout',
      component: () => import('./views/sign/out/SignOut.vue')
    },
    {
      path: '/signupv0',
      name: 'signupv0',
      component: () => import('./views/sign/up/SignUp.vue')
    },
    {
      path: '/portal/profile',
      name: 'portalprofile',
      component: () => import('./views/portal/profile/Profile.vue')
    },
    {
      path: '/portal/profile/password',
      name: 'portalchangepassword',
      component: () => import('./views/portal/profile/password/ChangePassword.vue')
    },
    {
      path: '/portal/home',
      name: 'portalhome',
      component: () => import('./views/portal/Home.vue')
    }, 
    {
      path: '/portal/settings',
      name: 'portalsettings',
      component: () => import('./views/portal/settings/Settings.vue')
    }, 
    {
      path: '/portal/operator/company/edit',
      name: 'portalcompanyedit',
      component: () => import('./views/portal/companys/Edit.vue')
    },    
    
    {
      path: '/portal/operator/vehicles',
      name: 'portalvehicles',
      component: () => import('./views/portal/vehicles/list/Vehicles.vue')
    },
    {
      path: '/portal/operator/vehicles/:id/edit',
      name: 'portalvehicleedit',
      component: () => import('./views/portal/vehicles/edit/Edit.vue')
    },
    {
      path: '/portal/operator/vehicles/addv2',
      name: 'portalvehicleaddv2',
      component: () => import('./portals/customer/operator/views/vehicle/add/VehicleAddView.vue')
    },
    {
      path: '/portal/operator/vehicles/:id/editv2',
      name: 'portalvehicleeditv2',
      component: () => import('./portals/customer/operator/views/vehicle/edit/VehicleEditView.vue')
    },
    {
      path: '/portal/operator/vehicles/:id/details',
      name: 'portalvehicledetails',
      component: () => import('./views/portal/vehicles/details/Details.vue')
    },
    {
      path: '/portal/operator/vehicles/:id/inspections',
      name: 'portalVehicleInspections',
      component: () => import('./portals/customer/operator/views/vehicle/inspections/Inspections.vue')
    }, 
    {
      path: '/portal/operator/vehicles/add/:id',
      name: 'portalvehicleadd',
      component: () => import('./views/portal/vehicles/add/Add.vue')
    },
    
    {
      path: '/portal/operator/inspections',
      name: 'portalinspections',
      component: () => import('./views/portal/inspections/list/Inspections.vue')
    },
    {
      path: '/portal/operator/inspections/:id/details',
      name: 'portalinspectiondetails',
      component: () => import('./portals/customer/operator/views/inspection/display/DisplayView.vue')
    },
    {
      path: '/portal/operator/inspections/:id/repair',
      name: 'portalinspectionrepairs',
      component: () => import('./views/portal/inspections/InspectionRepairs.vue')
    },
    {
      path: '/portal/operator/inspections/:id/remarks',
      name: 'portalInspectionRemarks',
      component: () => import('./views/portal/inspections/InspectionRemarks.vue')
    },

    {
      path: '/portal/operator/inspections/:id/email',
      name: 'portalInspectionEmail',
      component: () => import('./portals/customer/operator/views/inspection/Email.vue')
    },
    
    {
      path: '/portal/integrations/qbooks',
      name: 'integrationsQBooks',
      component: () => import('./portals/integrations/views/QBooks.vue')
    },

    {
      path: '/portal/operator/home',
      name: 'operatorHome',
      component: () => import('./portals/customer/operator/views/home/Home.vue')
    },
    {
      path: '/portal/operator/whatsnew',
      name: 'operatorWhatsNew',
      component: () => import('./portals/customer/operator/views/home/WhatsNew.vue')
    },
    
    {
      path: '/portal/operator/invoices',
      name: 'portalOperatorInvoices',
      component: () => import('./portals/customer/operator/views/invoice/list/Invoices.vue')
    },
    
    {
      path: '/portal/operator/companys',
      name: 'portalOperatorCompanySelect',
      component: () => import('./portals/customer/operator/views/company/Select.vue')
    },
    
    {
      path: '/portal/operator/billing/paid',
      name: 'operatorBillingPaid',
      component: () => import('./portals/customer/operator/views/billing/Paid.vue')
    },
    {
      path: '/portal/operator/billing/paymentLink',
      name: 'operatorBillingPaymentLink',
      component: () => import('./portals/customer/operator/views/billing/PaymentLink.vue')
    },
    {
      path: '/portal/operator/billing/details',
      name: 'operatorBillingDetails',
      component: () => import('./portals/customer/operator/views/billing/Details.vue')
    },
    {
      path: '/portal/operator/billing/edit',
      name: 'operatorBillingEdit',
      component: () => import('./portals/customer/operator/views/billing/UpdateBilling.vue')
    },

    {
      path: '/portal/operator/payment/edit',
      name: 'operatorPaymentEdit',
      component: () => import('./portals/customer/operator/views/payment/UpdateCard.vue')
    },

    {
      path: '/portal/operator/compliance',
      name: 'operatorCompliance',
      component: () => import('./portals/customer/operator/views/compliance/Compliance.vue')
    },
    
    {
      path: '/portal/operator/hours',
      name: 'operatorTimesheetList',
      component: () => import('./portals/customer/operator/views/timesheet/list/Timesheets.vue')
    },
    {
      path: '/portal/operator/hours/:id/details',
      name: 'operatorTimesheetDetails',
      component: () => import('./portals/customer/operator/views/timesheet/details/TimesheetDetails.vue')
    },
    
    {
      path: '/portal/operator/checkins',
      name: 'operatorCheckIns',
      component: () => import('./portals/customer/operator/views/checkin/list/CheckIns.vue')
    },
    {
      path: '/portal/operator/checkins/:id/details',
      name: 'operatorCheckInDetails',
      component: () => import('./portals/customer/operator/views/checkin/details/CheckInDetails.vue')
    },

    {
      path: '/portal/operator/schedules',
      name: 'portalOperatorSchedules',
      component: () => import('./portals/customer/operator/views/schedule/List.vue')
    },
    
    {
      path: '/portal/operator/checklists',
      name: 'portalOperatorCheckListLists',
      component: () => import('./portals/customer/operator/views/checklist/ListView.vue')
    },
    {
      path: '/portal/operator/checklists/add',
      name: 'portalOperatorCheckListAdd',
      component: () => import('./portals/customer/operator/views/checklist/AddView.vue')
    },
    {
      path: '/portal/operator/checklists/:id/details',
      name: 'portalOperatorCheckListDetails',
      component: () => import('./portals/customer/operator/views/checklist/DetailsView.vue')
    },
    {
      path: '/portal/operator/checklists/:id/edit',
      name: 'portalOperatorCheckListEdit',
      component: () => import('./portals/customer/operator/views/checklist/EditView.vue')
    },
    
    {
      path: '/portal/operator/schedules/:id/details',
      name: 'portalOperatorSchedulesDetails',
      component: () => import('./portals/customer/operator/views/schedule/Details.vue')
    },
    {
      path: '/portal/operator/schedules/:id/edit',
      name: 'portalOperatorSchedulesEdit',
      component: () => import('./portals/customer/operator/views/schedule/Edit.vue')
    },
    
    {
      path: '/portal/operator/users',
      name: 'portalOperatorUsers',
      component: () => import('./portals/customer/operator/views/employee/List.vue')
    },    
    {
      path: '/portal/operator/users/:id/edit',
      name: 'portalOperatorUsersEdit',
      component: () => import('./portals/customer/operator/views/employee/Edit.vue')
    },
    {
      path: '/portal/operator/users/license/:id',
      name: 'portalOperatorUsersLicense',
      component: () => import('./portals/customer/operator/views/employee/License.vue')
    },
    {
      path: '/portal/operator/users/file/:id',
      name: 'portalOperatorUsersFile',
      component: () => import('./portals/customer/operator/views/employee/File.vue')
    },
    {
      path: '/portal/operator/users/:id/inspections',
      name: 'portalOperatorUsersInspections',
      component: () => import('./portals/customer/operator/views/employee/Inspections.vue')
    },
    {
      path: '/portal/operator/users/:id/hours',
      name: 'portalOperatorUsersHours',
      component: () => import('./portals/customer/operator/views/employee/Hours.vue')
    },
    {
      path: '/portal/operator/users/:id/details',
      name: 'portalOperatorUsersDetails',
      component: () => import('./portals/customer/operator/views/employee/Details.vue')
    },
    {
      path: '/portal/operator/users/add',
      name: 'portalOperatorUsersAdd',
      component: () => import('./portals/customer/operator/views/employee/Add.vue')
    },
    {
      path: '/portal/operator/users/invite',
      name: 'portalOperatorUsersInvite',
      component: () => import('./portals/customer/operator/views/employee/Invite.vue')
    },
    

    {
      path: '/portal/admin/home',
      name: 'portaladminhome',
      component: () => import('./portals/admin/Home.vue')
    },
    {
      path: '/portal/admin/stats',
      name: 'portaladminstats',
      component: () => import('./portals/admin/stats/Stats.vue')
    },

    {
      path: '/portal/admin/lists',
      name: 'portaladminlists',
      component: () => import('./portals/admin/lists/Lists.vue')
    },
    {
      path: '/portal/admin/invoices',
      name: 'portalAdminInvoices',
      component: () => import('./portals/admin/invoices/Invoices.vue')
    },
    {
      path: '/portal/admin/checkins',
      name: 'portalAdminCheckIns',
      component: () => import('./portals/admin/checkins/CheckIns.vue')
    },
    {
      path: '/portal/admin/inspections',
      name: 'portalAdminInspections',
      component: () => import('./portals/admin/inspections/Inspections.vue')
    },
    {
      path: '/portal/admin/employees',
      name: 'portalAdminEmployees',
      component: () => import('./portals/admin/employees/Employees.vue')
    },
    {
      path: '/portal/admin/vehicles',
      name: 'portalAdminVehicles',
      component: () => import('./portals/admin/vehicles/Vehicles.vue')
    },
    {
      path: '/portal/admin/hours',
      name: 'portalAdminHours',
      component: () => import('./portals/admin/hours/Hours.vue')
    },
    {
      path: '/portal/admin/schedules',
      name: 'portalAdminSchedules',
      component: () => import('./portals/admin/schedules/Schedules.vue')
    },
    
    {
      path: '/portal/admin/new',
      name: 'adminCompanyNew',
      component: () => import('./portals/admin/register/New.vue')
    },
    {
      path: '/portal/admin/register',
      name: 'portaladminregister',
      component: () => import('./portals/admin/register/Register.vue')
    },
  
    {
      path: '/portal/admin/companys',
      name: 'portalAdminCompanys',
      component: () => import('./portals/admin/companys/Companys.vue')
    },
    {
      path: '/portal/admin/companys/:id/details',
      name: 'portalAdminCompanyDetails',
      component: () => import('./portals/admin/details/DetailsRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/billing',
      name: 'portalAdminCompanyBilling',
      component: () => import('./portals/admin/details/BillingRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/Compliance',
      name: 'portalAdminCompanyCompliance',
      component: () => import('./portals/admin/details/ComplianceRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/home',
      name: 'portalAdminCompanyStats',
      component: () => import('./portals/admin/details/StatsRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/employees',
      name: 'portalAdminCompanyEmployees',
      component: () => import('./portals/admin/details/EmployeesRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/vehicles',
      name: 'portalAdminCompanyVehicles',
      component: () => import('./portals/admin/details/VehiclesRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/inspections',
      name: 'portalAdminCompanyInspections',
      component: () => import('./portals/admin/details/InspectionsRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/hours',
      name: 'portalAdminCompanyHours',
      component: () => import('./portals/admin/details/HoursRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/checkins',
      name: 'portalAdminCompanyCheckIns',
      component: () => import('./portals/admin/details/CheckInsRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/invoices',
      name: 'portalAdminCompanyInvoices',
      component: () => import('./portals/admin/details/InvoicesRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/invoiceSettings',
      name: 'portalAdminCompanyInvoiceSettings',
      component: () => import('./portals/admin/details/BillingRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/schedules',
      name: 'portalAdminCompanySchedules',
      component: () => import('./portals/admin/details/SchedulesRow.vue')
    },
    {
      path: '/portal/admin/companys/:id/checklists',
      name: 'portalAdminCompanyChecklists',
      component: () => import('./portals/admin/details/ChecklistsRow.vue')
    },
    
  ]
})
