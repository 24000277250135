
import RcDomainMap from "../../common/RcDomainMap.js";
import User from './User.js';
import UserUtils from './UserUtils.js';

export default class UserMap extends RcDomainMap {
  static MODEL_NAME = User.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, UserMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new UserMap(this.domain, data);
  }
  
  sortByName() {
    var list = this.list();
    return list.sort(UserUtils.SortByFirst);
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getUser(key);
      values.push(item);
    }
    return values;
  }
  
  getUser(key) {
    var value = super.get(key);
    return new User(this.domain, value);
  }
}